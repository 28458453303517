import React, { lazy, Suspense } from 'react'

// import firebaseConfig from './firebase/'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import PATH from 'path.config'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import RouteTracer from 'hooks/RouteTracer'
import Layout from './layouts'

const routes = [
  // Dashboards
  {
    path: PATH.HOME,
    key: PATH.HOME,
    Component: lazy(() => import('./pages/Welcome/StoredWelcome')),
    exact: true,
  },
  {
    path: PATH.STORED_WELCOME,
    key: PATH.STORED_WELCOME,
    Component: lazy(() => import('./pages/Welcome/StoredWelcome')),
    exact: true,
  },
  {
    path: PATH.WELCOME,
    key: PATH.WELCOME,
    Component: lazy(() => import('./pages/Welcome')),
    exact: true,
  },
  {
    path: PATH.ABOUT,
    key: PATH.ABOUT,
    Component: lazy(() => import('./pages/About')),
    exact: true,
  },
  {
    path: PATH.PROGRAMS,
    key: PATH.PROGRAMS,
    Component: lazy(() => import('./pages/Program')),
    exact: true,
  },
  {
    path: PATH.SPONSOR,
    key: PATH.SPONSOR,
    Component: lazy(() => import('./pages/Sponsor')),
    exact: true,
  },
  {
    path: PATH.SPEAKER,
    key: PATH.SPEAKER,
    Component: lazy(() => import('./pages/Speaker')),
    exact: true,
  },
  {
    path: PATH.MYPAGE,
    key: PATH.MYPAGE,
    Component: lazy(() => import('./pages/MyPage')),
    exact: true,
  },
  {
    path: PATH.LOCATION,
    key: PATH.LOCATION,
    Component: lazy(() => import('./pages/Location')),
    exact: true,
  },

  {
    path: PATH.GUIDE,
    key: PATH.GUIDE,
    Component: lazy(() => import('./pages/Guide')),
    exact: true,
  },
  {
    path: PATH.GRADE_INFORMATION,
    key: PATH.GRADE_INFORMATION,
    Component: lazy(() => import('./pages/GradeInformation')),
    exact: true,
  },
  {
    path: PATH.LIVE,
    key: PATH.LIVE,
    Component: lazy(() => import('./pages/Live')),
    exact: true,
  },
  {
    path: `${PATH.LIVE}/:id?`,
    key: PATH.LIVE,
    Component: lazy(() => import('./pages/Live')),
    exact: true,
  },
  {
    path: PATH.VOD,
    key: PATH.VOD,
    Component: lazy(() => import('./pages/VOD')),
    exact: true,
  },
  {
    path: `${PATH.SURVEY}/:id?`,
    key: PATH.SURVEY,
    Component: lazy(() => import('./pages/Survey')),
    exact: true,
  },
  {
    path: PATH.SURVEY_COMPLETE,
    key: PATH.SURVEY_COMPLETE,
    Component: lazy(() => import('./pages/Survey/complete')),
    exact: true,
  },
  {
    path: `${PATH.HISTORY}/:id?`,
    key: PATH.HISTORY,
    Component: lazy(() => import('./pages/History')),
    exact: true,
  },
  {
    path: PATH.TABLET,
    key: PATH.TABLET,
    Component: lazy(() => import('./pages/Tablet')),
    exact: true,
  },

  {
    path: PATH.AUTH.LOGIN,
    key: PATH.AUTH.LOGIN,
    Component: lazy(() => import('./pages/Auth/Login')),
    exact: true,
  },
  {
    path: PATH.AUTH.LOGOUT,
    key: PATH.AUTH.LOGOUT,
    Component: lazy(() => import('./pages/Auth/Logout')),
    exact: true,
  },
  {
    path: PATH.AUTH.REGISTER,
    key: PATH.AUTH.REGISTER,
    Component: lazy(() => import('./pages/Auth/Signup')),
    exact: true,
  },
  {
    path: PATH.AUTH.FORGOT_PASSWORD,
    key: PATH.AUTH.FORGOT_PASSWORD,
    Component: lazy(() => import('./pages/Auth/ForgotPassword')),
    exact: true,
  },
  {
    path: PATH.AUTH.REGISTER_COMPLETE,
    key: PATH.AUTH.REGISTER_COMPLETE,
    Component: lazy(() => import('./pages/Auth/Complete')),
    exact: true,
  },
  {
    path: PATH.AUTH.REGISTER_FAILED,
    key: PATH.AUTH.REGISTER_FAILED,
    Component: lazy(() => import('./pages/Auth/Complete/failed')),
    exact: true,
  },
  {
    path: PATH.AUTH.REGISTER_INFO,
    key: PATH.AUTH.REGISTER_INFO,
    Component: lazy(() => import('./pages/Auth/Information')),
    exact: false,
  },
  {
    path: PATH.AUTH.REGISTER_CONFIRM,
    key: PATH.AUTH.REGISTER_CONFIRM,
    Component: lazy(() => import('./pages/Auth/Confirm')),
    exact: false,
  },
  {
    path: PATH.SYSTEM_404,
    key: PATH.SYSTEM_404,
    Component: lazy(() => import('./pages/404')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <RouteTracer />
        <Route
          render={(state) => {
            const { location } = state
            // console.log(location)
            // if (location.pathname === PATH.AUTH.LOGIN) {
            //   routerAnimation = 'none'
            // }
            // routerAnimation = 'none'

            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
                    {routes.map(({ path, Component, exact, key }) => {
                      // console.log(key)
                      return (
                        <Route
                          path={path}
                          key={key}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      )
                    })}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
