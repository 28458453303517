// eslint-disable-next-line no-unused-vars
import { all, takeEvery, takeLatest, take, put, call, select } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { notification } from 'antd'
import { history } from 'App'
// import { push } from 'react-router-redux'
import _ from 'lodash'

import * as ko from 'locales/ko-KR'
import * as en from 'locales/en-US'
import * as firebase from 'services/firebase'
// import { USER } from 'constant'
import PATH from 'path.config'
import { fireStore } from '../../firebase'
import actions from './actions'

let locale = ko.default
if (process.env.REACT_APP_LOCALE === 'en-US') {
  locale = en.default
}

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    updateUser: firebase.updateUser,
    deleteUser: firebase.deleteUser,
    sendFindPasswordMail: firebase.sendFindPasswordMail,
  },
}
// eslint-disable-next-line no-unused-vars
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time))

// const redirectTo = location => {
//   history.push(location)
// }

export function* LOGIN({ payload }) {
  const { email, password, redirect } = payload
  // const settings = yield select((state) => state.Project.settings)
  // const { multiSignInAllowed } = settings
  // console.log('multi Sign in allowed', multiSignInAllowed)

  // console.log(payload)
  let loginRedirectPath = PATH.HOME
  if (redirect) {
    loginRedirectPath = redirect
  }
  const { authProvider: autProviderName } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  // console.log(success);

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    // yield put({
    //   type: 'user/SET_STATE',
    //   payload: {
    //     authorized: true,
    //   },
    // })

    yield history.push(loginRedirectPath)

    // notification.success({
    //   message: '로그인',
    //   description: 'Welcome to Tech seminar 2021',
    //   duration: 2,
    // })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  // console.log('logout saga')
  const { authProvider } = yield select((state) => state.settings)
  // eslint-disable-next-line no-unused-vars
  const result = yield call(mapAuthProviders[authProvider].logout)
  const initialUser = {
    type: 'user/SET_STATE',
    payload: {
      id: '',
      profile: {},
      role: '',
      email: '',
      allowed: false,
      surveyAllowed: false,
      chatAllowed: false,
      completedSurveys: [],
      authorized: false,
      loading: false,
      blocked: false,
      loginToken: '',
      acceptableSchedules: null,
      defaultSchedule: null,
    },
  }
  yield put(initialUser)
  // try {
  //   const data = yield call(rsFirebase.auth.signOut)
  // } catch (error) {
  //   yield put(initialUser)
  // }

  // console.log('logout')

  // yield history.push(PATH.HOME)
}

export function* REGISTER({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { email, password, ...data } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
    },
  })

  const settings = yield select((state) => state.Project.settings)

  // yield put(push(PATH.AUTH.REGISTER_COMPLETE))s

  data.allowed = true
  data.surveyComplete = false
  // eslint-disable-next-line no-unused-vars
  const { authProvider } = yield select((state) => state.settings)

  let defaultAllowedSchedules = []
  let defaultUserSchedule = null
  if (settings && _.has(settings, 'defaultAllowedSchedules')) {
    // eslint-disable-next-line prefer-destructuring
    // console.log('settings.defaultAllowedSchedules', settings.defaultAllowedSchedules)
    defaultAllowedSchedules = settings.defaultAllowedSchedules
  }
  if (settings && _.has(settings, 'defaultUserSchedule')) {
    // eslint-disable-next-line prefer-destructuring
    // console.log('settings.defaultUserSchedule', settings.defaultUserSchedule)
    defaultUserSchedule = settings.defaultUserSchedule
  }

  data.acceptableSchedules = defaultAllowedSchedules || []
  data.defaultSchedule = defaultUserSchedule || null

  if (_.has(data, 'profile') === false) {
    data.profile = null
  }
  console.log('before', data.profile)
  if (data.profile) {
    _.forEach(data.profile, (value, key) => {
      console.log('=>', key, value)
      if (!value) {
        data.profile[key] = null
      }
      if (_.isObject(value)) {
        // console.log(value)
        _.forEach(value, (v, k) => {
          if (!v) {
            value[k] = null
          }
        })
      }
    })
  }
  console.log('after', data.profile)

  const success = yield call(mapAuthProviders[authProvider].register, email, password, data)

  // return
  // eslint-disable-next-line no-unreachable
  // const success = true
  // yield call(delay, 2000)
  // const success = true

  if (success) {
    // yield history.push(PATH.AUTH.REGISTER_COMPLETE)
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: true,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Registration Complete.',
      duration: 2,
    })

    if (settings.registerSurveyId) {
      const surveyPath = `${PATH.SURVEY}/${settings.registerSurveyId}`
      console.log(surveyPath)
      yield history.push(surveyPath)
    } else {
      yield history.push(PATH.AUTH.REGISTER_COMPLETE)
    }
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push(PATH.AUTH.REGISTER_FAILED)
  }
}

export function* USER_EDIT({ payload }) {
  // console.log('USER_EDIT', payload)
  // const { email, name, nickname, phone, department, work, attendance, type, role } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  // console.log(mapAuthProviders[authProvider].updateUser)

  const success = yield call(mapAuthProviders[authProvider].updateUser, payload)

  if (success) {
    notification.success({
      message: '수정 완료.',
      description: '참가자 정보가 수정되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}
export function* USER_DELETE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  // console.log(mapAuthProviders[authProvider].updateUser)
  const success = yield call(mapAuthProviders[authProvider].deleteUser, payload)
  if (success) {
    notification.success({
      message: '삭제 완료.',
      description: '참가자가 삭제되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log('load currentuser')
  const { authProvider } = yield select((state) => state.settings)
  const user = yield call(mapAuthProviders[authProvider].currentAccount)

  // console.log('sagas', user)
  if (user) {
    const ref = fireStore.collection('users').doc(user.uid)
    const channel = eventChannel((emit) => ref.onSnapshot(emit))

    try {
      while (true) {
        const settings = yield select((state) => state.Project.settings)
        // console.log(settings)
        let multiSignInAllowed = true
        if (settings && _.has(settings, 'multiSignInAllowed')) {
          multiSignInAllowed = settings.multiSignInAllowed
        }

        const profileData = yield take(channel)
        // console.log(profileData.data())
        const {
          role,
          email,
          profile,
          allowed,
          surveyAllowed,
          chatAllowed,
          completedSurveys,
          blocked,
          loginToken,
          acceptableSchedules,
          defaultSchedule,
        } = profileData.data()

        // console.log('currentUser', loginToken)

        if (blocked === true) {
          channel.close()
          notification.error({
            message: 'Access Denied.',
            description: "You don't have permission to access this page.",
            duration: 5,
          })
          yield put({
            type: 'user/LOGOUT',
          })
        } else {
          if (multiSignInAllowed === false) {
            // console.log('multi sign in allowed', multiSignInAllowed)
            // console.log(loginToken, localStorage.getItem('signInToken'))
            if (loginToken !== localStorage.getItem('signInToken')) {
              channel.close()
              notification.error({
                message: locale.messages['auth.error.multiSignIn.title'],
                description: locale.messages['auth.error.multiSignIn.description'],
                duration: 5,
              })
              yield put({
                type: 'user/LOGOUT',
              })
            }
          }

          const userData = {
            id: user.uid,
            role,
            email,
            surveyAllowed: surveyAllowed || false,
            chatAllowed: chatAllowed || false,
            completedSurveys: completedSurveys || [],

            profile,
            authorized: true,
            blocked: blocked || false,
            loginToken: loginToken || null,
            allowed: allowed || false,
            acceptableSchedules: acceptableSchedules || [],
            defaultSchedule: defaultSchedule || null,
          }
          yield put({
            type: 'user/SET_STATE',
            payload: userData,
          })
        }
      }
    } catch (_error) {
      // console.log(error)
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  const { authProvider } = yield select((state) => state.settings)
  // console.log(payload)
  const result = yield call(mapAuthProviders[authProvider].sendFindPasswordMail, payload.email)
  // console.log(result)
  if (result) {
    yield history.push(PATH.AUTH_LOGIN)
    notification.success({
      message: 'Password reset mail has send.',
      description: 'Check your email and change to new password',
      duration: 3,
    })
  } else {
    notification.error({
      message: 'Send mail error',
      description: 'Email address does not exist',
      duration: 3,
    })
  }
}

export function* SET_PAGE({ payload }) {
  const { authProvider } = yield select((state) => state.settings)
  if (payload.user && payload.user.authorized && payload.user.id && payload.location) {
    const data = {
      id: payload.user.id,
      values: { lastOnPage: payload.location.pathname || null },
    }
    yield call(mapAuthProviders[authProvider].updateUser, data)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.USER_DELETE, USER_DELETE),
    takeEvery(actions.USER_EDIT, USER_EDIT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.SET_PAGE, SET_PAGE),

    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
